<script>
	import Loader from './Loader.svelte';
	import Popup from './Popup.svelte';
    import Heading from '../components/Heading.svelte';
    import User from '../services/User.js';
    import PopupHelper from '../helpers/Popup';

    let isPopupOpen = false;
    let isLoading = false;

    let senhaPadrao = "TJwt7BebD6BdR6ux";

    let tipoUsuario = "";
    let emailInput = "";
    let emailCompleto = "";
    const dominioLoovi = "@loovi.com.br";
    let funcionarioIsChecked = false;
    let administradorIsChecked = false;

    function handleSelection(event) {
        tipoUsuario = event.target.value;
    }

    function handleInputText(e){
        emailInput = e.target.value;
    }

    async function handleSubmit(e) {
        e.preventDefault();

        isLoading = true;
        emailCompleto = emailInput + dominioLoovi;

        if(tipoUsuario == 'funcionario'){
            const result = await User.createFuncionario(emailCompleto, senhaPadrao);

            if(result){
                limpaInputs();
                isLoading = false;
                isPopupOpen = false;
                isPopupOpen = true;
            }else{
                PopupHelper.alert('Erro de Servidor', result.error);
                isLoading = false;
            }
        }else if(tipoUsuario == 'administrador'){
            const result = await User.createAdministrador(emailCompleto, senhaPadrao);

            if(result){
                limpaInputs();
                isLoading = false;
                isPopupOpen = false;
                isPopupOpen = true;
            }else{
                PopupHelper.alert('Erro de Servidor', result.error);
                isLoading = false;
            }
        }
    }

    function limpaInputs(){
        emailInput = "";
        tipoUsuario = "";
        funcionarioIsChecked = false;
        administradorIsChecked = false;
    }
</script>

<Loader visible={isLoading} />

<main>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="pai">
            <Heading main="Criar usuário do Mercúrio" />

            <div class="painel">
                <form on:submit={handleSubmit}>
                    <h1 class="h1Selecione">Selecione o tipo de usuário:</h1>

                    <div class="opcoes">
                        <div id="tipos">
                            <label>
                                <input type="radio" name="tipoUsuario" value="funcionario" on:change={handleSelection} bind:group={tipoUsuario}>
                                <span>Funcionário</span>
                            </label>
                            <label>
                                <input type="radio" name="tipoUsuario" value="administrador" on:change={handleSelection} bind:group={tipoUsuario}>
                                <span>Administrador</span>
                            </label>
                        </div>
                    </div>

                    <div>
                        <h1>Insira o email:</h1>
                        <input type="text" class="inputEmail" on:input={handleInputText} value={emailInput} spellcheck="false">
                        <label class="spanGmail">@loovi.com.br</label>
                    </div>

                    {#if tipoUsuario.length > 0 && emailInput.length > 0}
                        <div class="divSubmit">
                            <input class="btn altura" type="submit" value="Criar">
                        </div>
                    {/if}
                </form>
            </div>
        </div>
</main>

<Popup
	visible={isPopupOpen}
	title="Executado com Sucesso!"
	message={`Usuário criado com sucesso. Senha: ${senhaPadrao}`} />

<style>
    #tipos label {
        float:left;
        width:130px;
        margin:4px;
        background-color:#EFEFEF;
        border-radius:4px;
        border:1px solid #D0D0D0;
    }

    #tipos label span {
        text-align:center;
        padding:13px 0px;
        display:block;
    }

    #tipos label input {
        display: none;
    }

    #tipos input:checked + span {
        background-color:#87CEEB;
    }

    .h1Selecione{
        margin-bottom: 15px;
    }

    .divSubmit{
        text-align: center;
        margin-top: 25px;
    }

    .altura{
        height: 35px;
        cursor: pointer;
    }

    .inputEmail{
        height: 30px;
        border-radius: 5px 0px 0px 5px;
        border: 1px solid #333;
        font-size: 15px;
        width: 140px;
        padding: 10px;
    }

    .spanGmail{
        border-radius: 0px 5px 5px 0px;
        display: inline-block;
        height: 30px;
        margin-left: -4px;
        font-size: 14px;
        transition: .5s;
        border: 1px solid #333;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 7.5px;
        background-color: #D0D0D0;
        font-weight: bold;
    }

    form h1{
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 15px;
    }

    .opcoes{
        display: flex;
        margin-top: -8px;
        margin-bottom: 30px;
    }

    .opcoes div{
        margin-right: 42px;
    }

    .opcoes span{
        display: inline-block;
        cursor: pointer;
        font-size: 15px;
        height: 30px;
        padding: 1px;
        line-height: 5px;
    }

    .opcoes label input:checked + span{
      font-weight: bold;
    }

    .pai{
        max-width: 1004px;
        margin: 0 auto;
    }

    .painel{
        background: #fff;
        border-radius: 5px;
        border: 1px solid var(--color-black-20);
        display: flex;
    }

    form{
        width: 100%;
        padding: 11px 13px;
    }
</style>