import { URLS, api_keyed_login, api_auth } from "../helpers/Request";

export default {
  async login(email, password) {
    try {
      const data = await api_keyed_login.post(`${URLS.AUTENTICACAO}/funcionario/${email}/verifica-senha`, {
        json: password
      }).json();
      return data;
    }catch(e){
      throw e;
    }
  },
  async loginAdministrador(email, password) {
    try {
      const data = await api_keyed_login.post(`${URLS.AUTENTICACAO}/administrador/${email}/verifica-senha`, {
        json: password
      }).json();
      return data;
    }catch(e){
      throw e;
    }
  },
  async updatePassword(email, currentPassword, newPassword, accessToken) {
    try {
      await api_auth.put(`${URLS.USUARIO}/funcionario/${email}/altera-senha`, {
        json: {
          SenhaAtual: currentPassword,
          NovaSenha: newPassword,
          AccessToken: accessToken,
        }
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async updatePasswordAdministrador(email, currentPassword, newPassword, accessToken) {
    try {
      await api_auth.put(`${URLS.USUARIO}/administrador/${email}/altera-senha`, {
        json: {
          SenhaAtual: currentPassword,
          NovaSenha: newPassword,
          AccessToken: accessToken,
        }
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async createFuncionario(email, password) {
    try {
      const data = await api_auth.put(`${URLS.USUARIO}/funcionario/${email}`, {
        json: password
      });
      return data;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async createAdministrador(email, password) {
    try {
      const data = await api_auth.put(`${URLS.USUARIO}/administrador/${email}`, {
        json: password
      });
      return data;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}