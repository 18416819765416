<script>
    import Loader from './Loader.svelte';
    import Popup from './Popup.svelte';
    import Heading from '../components/Heading.svelte';
    import ServiceEmail from '../services/Email.js';
  
    let isPopupOpen = false;
    let isPopupErrorOpen = false
    let isLoading = false;

    let nome = '';
    let sobrenome = '';
    let email = '@loovi.com.br';

    let semSeparacao = false;

    function handleSubmit(e){
        e.preventDefault();

        createUser();
    }

    async function createUser(){
        isLoading = true;

        const infoUser = {
            Nome: nome,
            Sobrenome: sobrenome || 'loovi',
            Email: email
        };

        const result = await ServiceEmail.createEmail(infoUser);

        if(result){
            isLoading = false;
            isPopupOpen = false;
            isPopupOpen = true;
            limpaInformacoes();
        }

        if(!result || result.error){
            isLoading = false;
            isPopupErrorOpen = false;
            isPopupErrorOpen = true;
        }
    }

    function handleInputNome(event){
        nome = event.target.value;

        if(semSeparacao){
            email = `${nome}@loovi.com.br`
        }else{
            email = `${nome}.${sobrenome}@loovi.com.br`
        }
    }

    function handleInputSobrenome(event){
        sobrenome = event.target.value;

        if(semSeparacao){
            email = `${nome}@loovi.com.br`
        }else{
            email = `${nome}.${sobrenome}@loovi.com.br`
        }
    }

    function limpaInformacoes(){
        nome = '';
        sobrenome = '';
        email = '@loovi.com.br';
    }
    
    function checkSemSeparador(){
        semSeparacao = !semSeparacao;
        sobrenome = '';
        email = `${nome}@loovi.com.br`
    }
</script>
  
<Loader visible={isLoading} />

<main>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="pai">
        <Heading main="Criar email Google" />

        <div class="painel">
            <form on:submit={handleSubmit}>
                <div class="input-container">
                    <div>
                        <h1>Nome</h1>
                        <input type="text" class="inputNome" spellcheck="false" on:input={handleInputNome} value={nome}>
                    </div>
                    {#if !semSeparacao}
                        <div>
                            <h1>Sobrenome</h1>
                            <input type="text" class="inputSobrenome" spellcheck="false" on:input={handleInputSobrenome} value={sobrenome}>
                        </div>
                    {/if}
                </div>
                
                <div class="checkbox">
                    <input type="checkbox" id="criarSemSeparador" on:change={checkSemSeparador}>
                    <label for="criarSemSeparador">
                        <span class={semSeparacao ? 'bold' : ''}>Criar sem separador</span>
                    </label>
                </div>

                <div class="divPreviewEmail">
                    <h1>Email a ser criado</h1>
                    <span class="spanPreviewEmail">{email}</span>
                </div>

                {#if (nome.length > 0 && sobrenome.length > 0 && !semSeparacao) || semSeparacao && nome.length > 0}
                    <div class="divSubmit">
                        <input class="btn altura" type="submit" value="Criar">
                    </div>
                {/if}
            </form>
        </div>
    </div>
</main>
  
<Popup
    visible={isPopupOpen}
    title="Parabéns"
    message="O usuário foi criado com sucesso! Senha: teste123"
/>

<Popup
    visible={isPopupErrorOpen}
    title="ERRO"
    message="Tivemos um erro e o email não foi criado corretamente. Tente novamente"
/>

<style>
    .checkbox {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    .checkbox input[type="checkbox"] {
        margin-right: 6px;
        cursor: pointer;
    }

    .checkbox span{
        display: inline-block;
        line-height: 15px;
        cursor: pointer;
        height: 18px;
    }

    .checkbox span::selection{
        background-color: transparent;
    }

    .bold{
        font-weight: bold !important;
    }

    .divPreviewEmail{
        margin-top: 25px;
    }

    .spanPreviewEmail{
        border-radius: 5px;
        display: inline-block;
        height: 30px;
        font-size: 14px;
        transition: .5s;
        border: 1px solid #333;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 7.5px;
        background-color: #D0D0D0;
        font-weight: bold;
        margin-top: 5px;
    }

    .input-container {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    .divSubmit{
        text-align: center;
        margin-top: 25px;
    }

    .altura{
        height: 35px;
        cursor: pointer;
    }

    .inputNome{
        height: 30px;
        border-radius: 5px 0px 0px 5px;
        border: 1px solid #333;
        font-size: 15px;
        width: 140px;
        padding: 10px;
    }

    .inputSobrenome{
        height: 30px;
        border-radius: 0px 5px 5px 0px;
        border: 1px solid #333;
        font-size: 15px;
        width: 140px;
        padding: 10px;
    }

    form h1{
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 15px;
    }

    .pai{
        max-width: 1004px;
        margin: 0 auto;
    }

    .painel{
        background: #fff;
        border-radius: 5px;
        border: 1px solid var(--color-black-20);
        display: flex;
    }

    form{
        width: 100%;
        padding: 11px 13px;
    }

    .divSubmit{
        text-align: center;
        margin-top: 60px;
    }

    .btn{
        cursor: pointer;
    }
</style>